<template>
    <div class="page restaurant-list">
        <div class="top">
            <div class="content">
                <div class="left"
                     @click="toSetCity">
                    <div class="text">{{initCity.city.name}}</div>
                    <img class="arrow"
                         src="@/assets/img/film_xia.png" />
                </div>
                <div class="right">
                    <input type="text"
                           class="right-input"
                           v-model="kw"
                           placeholder="请输入关键字搜索" />
                </div>

                <div class="close-btn"
                     v-if="kw"
                     @click="clearKw">
                    <img class="close-img"
                         src="@/assets/img/clear_icon.png" />
                </div>
            </div>
        </div>
        <div class="con-list">
            <div class="tab">
                <div class="box"
                     :class="{'select': current == 0}"
                     @click="changeSelect(0)">附近餐厅（{{restaurantNum.nearby}}）</div>
                <div class="box"
                     :class="{'select': current == 1}"
                     @click="changeSelect(1)">收藏的餐厅（{{restaurantNum.favorite}}）</div>
                <div class="line"
                     :style="lineStyle"></div>
            </div>
            <div class="list"
                 v-for="(item , index) in queryData"
                 :key="index"
                 v-if="queryData.length>0">
                <div class="left"
                     @click="toMenu(index)">
                    <div class="name">{{item.storeName}}</div>
                    <div class="addr">{{item.address}}</div>
                </div>
                <div class="right"
                     @click="changeLove(index)">
                    <img class="love"
                         src="@/assets/kfc-img/kfc-love.png"
                         v-if="item.isFavorite" />
                    <img class="love"
                         src="@/assets/kfc-img/kfc-no-love.png"
                         v-if="!item.isFavorite" />
                    <div class="num">{{item.distance}}</div>
                </div>
            </div>
            <div v-if="queryData.length<1"
                 class="none-length">
                暂无搜索结果！
            </div>
        </div>

        <div class="dingwei"
             bindtap="dingwei"
             v-if="dingweiShow">
            <img class="dw-image"
                 src="@/assets/img/icon_dw.png" />
            定位: {{nowCity}}
            <div class="dw-go"
                 v-if="!dingweiLoading">
                {{nowCity=='定位未开启'?'去定位':'重新定位'}}<img class="dwgo-image"
                     src="@/assets/img/icon_dwgo.png" />
            </div>
            <img v-if="dingweiLoading"
                 class="dwload-image"
                 src="@/assets/img/dw_loading.gif" />
        </div>
        <Scroll :onBottom="onBottom"
                :lowerThreshold="200" />
    </div>
</template>
<script>
    import { GET_LOVE_STOP, GET_LARTELY_STOP, GET_STOP_NUM, SET_LOVE_STOP } from 'api';
    import { PAGE_SET_CITY, PAGE_STOP_MENU } from 'page';
    import Scroll from '@/components/scroll';
    export default {
        data() {
            return {
                current: 0,
                stopLength: 2,
                queryData: [],
                dingweiShow: false,
                dingweiLoading: false,
                restaurantNum: {
                    favorite: 0,
                    nearby: 0
                },
                kw: '',
                pageSize: 10,
                pageIndex: 1,
                isBottom: false,
            }
        },
        computed: {
            initCity() {
                if (this.$store.state.location.selectLocation.city.id) {
                    return this.$store.state.location.selectLocation;
                }
                else {
                    return this.$store.state.location.currentLocation;
                }
            },
            lineStyle() {
                let baseStep = 100 / this.stopLength
                return `left:${baseStep * (this.current + 1)}%;margin-left:-${baseStep / 2}%;`
            }
        },
        watch: {
            kw(val) {
                this.pageIndex = 1;
                this.getData(1);
            }
        },
        components: {
            Scroll
        },
        created() {
            this.getData();
            this.getNum();
        },
        methods: {
            // 获取餐厅数量
            getNum() {
                this.$util.post(GET_STOP_NUM, {
                    areaCityId: this.initCity.city.id,
                    kw: this.kw
                }).then(res => {
                    if (res.success) {
                        this.restaurantNum = res.data;
                    }
                })
            },
            // 获取餐厅
            getData(type = 0) {
                let url = this.current == 0 ? GET_LARTELY_STOP : GET_LOVE_STOP;
                this.$util.post(url, {
                    areaCityId: this.initCity.city.id,
                    lon: this.initCity.lon,
                    lat: this.initCity.lat,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    kw: this.kw
                }).then(res => {
                    if (res.success) {
                        if (res.data.length < this.pageSize) {
                            this.isBottom = true;
                        }
                        else {
                            this.isBottom = false
                        }
                        if (type == 1) {
                            this.queryData = [];
                        }
                        this.queryData = [...this.queryData, ...res.data];
                    }
                })
            },
            // 收藏餐厅
            changeLove(index) {
                this.queryData[index].isFavorite = !this.queryData[index].isFavorite;
                if (this.queryData[index].isFavorite) {
                    this.restaurantNum.favorite++;
                }
                else {
                    this.restaurantNum.favorite--;
                }
                this.$util.post(SET_LOVE_STOP, {
                    storeCode: this.queryData[index].storeCode,
                    favorite: this.queryData[index].isFavorite
                }).then(res => {
                    if (!res.success) {
                        if (!this.queryData[index].isFavorite) {
                            this.restaurantNum.favorite++;
                        }
                        else {
                            this.restaurantNum.favorite--;
                        }
                        this.queryData[index].isFavorite = !this.queryData[index].isFavorite;
                    }
                })
            },
            // 切换餐厅
            changeSelect(index) {
                if (this.current == index) {
                    return;
                }
                this.current = index;
                this.pageIndex = 1;
                this.getData(1);
            },
            // 去餐厅商品
            toMenu(index) {
                this.$util.pageJump(PAGE_STOP_MENU, {
                    storeCode: this.queryData[index].storeCode,
                    storeName: this.queryData[index].storeName,
                    address: this.queryData[index].address,
                })
            },
            // 选择城市
            toSetCity() {
                this.$util.pageJump(PAGE_SET_CITY)
            },
            // 清除查询内容
            clearKw() {
                this.kw = '';
            },
            // 触底事件
            onBottom() {
                if (this.isBottom) {
                    return
                }
                this.pageIndex++;
                this.getData();
            }
        }
    }
</script>
<style lang="less" scoped>
    @import url("~@/lib/base.less");

    .top {
      padding: .pxToRem(20) [] .pxToRem(24) [];
      width: .pxToRem(702) [];
      background-color: #ffffff;
      border-bottom: .pxToRem(20) [] solid #f5f5f5;
    }

    .top .content {
      position: relative;
      padding: 0 .pxToRem(40) [];
      width: .pxToRem(622) [];
      height: .pxToRem(67) [];
      line-height: .pxToRem(67) [];
      background-color: #f3f3f3;
      border-radius: .pxToRem(34) [];
      display: flex;
      justify-content: flex-start;
      .left {
        min-width: .pxToRem(130) [];
        display: flex;
        // justify-content: start;
        font-size: .pxToRem(28) [];
        font-weight: 800;
        color: #3f3f3f;
      }
    }

    .top .content .left .text {
      max-width: .pxToRem(82) [];
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    .top .content .left .arrow {
      width: .pxToRem(58) [];
      height: .pxToRem(32) [];
      transform: scale(0.8);
      margin-top: .pxToRem(20) [];
    }

    .top .content .right {
      position: absolute;
      top: 0;
      left: .pxToRem(174) [];
      right: .pxToRem(67) [];
      bottom: 0;
      height: .pxToRem(67) [];
      .right-input {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: .pxToRem(67) [];
        line-height: .pxToRem(67) [];
        font-size: .pxToRem(28) [];
        background: none;
      }
    }

    .close-btn {
      position: absolute;
      top: 0;
      right: 0;
      width: .pxToRem(67) [];
      height: .pxToRem(67) [];
    }

    .close-btn .close-img {
      position: absolute;
      top: .pxToRem(20) [];
      right: .pxToRem(20) [];
      width: .pxToRem(32) [];
      height: .pxToRem(32) [];
    }

    .con-list {
      width: 100%;
      background-color: #ffffff;
    }

    .con-list .none-length {
      width: 100%;
      height: .pxToRem(400) [];
      line-height: .pxToRem(400) [];
      text-align: center;
    }

    .con-list .tab {
      width: 100%;
      height: .pxToRem(92) [];
      line-height: .pxToRem(92) [];
      display: flex;
      justify-content: space-between;
      position: relative;
    }

    .con-list .tab .box {
      flex: 1;
      font-size: .pxToRem(28) [];
      font-weight: 800;
      text-align: center;
    }

    .con-list .tab .select {
      color: #d62e34;
    }

    .con-list .tab .line {
      position: absolute;
      width: .pxToRem(46) [];
      height: .pxToRem(6) [];
      background-color: #d62e34;
      border-radius: .pxToRem(6) [];
      bottom: 0;
      transform: translate(-50%, 0);
      transition: all 0.3s;
    }

    .con-list .list {
      width: .pxToRem(702) [];
      margin-left: .pxToRem(24) [];
      height: .pxToRem(134) [];
      line-height: .pxToRem(134) [];
      position: relative;
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #ebeef0;
    }

    .list .left {
      width: .pxToRem(562) [];
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .list .left .name {
      font-size: .pxToRem(32) [];
      font-weight: 500;
      height: .pxToRem(32) [];
      line-height: .pxToRem(32) [];
      margin-top: .pxToRem(30) [];
    }

    .list .left .addr {
      font-size: .pxToRem(24) [];
      font-weight: 500;
      color: #666666;
      line-height: .pxToRem(24) [];
      margin-top: .pxToRem(20) [];
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .list .right {
      text-align: right;
      position: relative;
    }

    .list .right .love {
      width: .pxToRem(41) [];
      height: .pxToRem(39) [];
      position: absolute;
      top: .pxToRem(20) [];
      right: .pxToRem(38) [];
    }

    .list .right .num {
      position: absolute;
      top: .pxToRem(78) [];
      right: 0;
      font-size: .pxToRem(24) [];
      font-weight: 500;
      color: #666666;
      height: .pxToRem(24) [];
      line-height: .pxToRem(24) [];
    }

    .dingwei {
      position: fixed;
      width: 100%;
      height: .pxToRem(75) [];
      line-height: .pxToRem(75) [];
      background: #f5f5f5;
      border-radius: .pxToRem(20) [] .pxToRem(20) [] 0 0;
      left: 0;
      bottom: 0;
      color: #999999;
      font-size: .pxToRem(26) [];
    }

    .dingwei .dw-image {
      width: .pxToRem(29) [];
      height: .pxToRem(33) [];
      margin: 0 .pxToRem(22) [] 0 .pxToRem(33) [];
      vertical-align: .pxToRem(-7) [];
    }

    .dingwei .dw-go {
      float: right;
    }

    .dingwei .dw-go .dwgo-image {
      width: .pxToRem(18) [];
      height: .pxToRem(20) [];
      margin: 0 .pxToRem(44) [] 0 .pxToRem(11) [];
    }

    .dingwei .dwload-image {
      float: right;
      width: .pxToRem(38) [];
      height: .pxToRem(41) [];
      margin: .pxToRem(20) [] .pxToRem(20) [] 0 .pxToRem(11) [];
    }
</style>
